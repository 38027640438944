<template>
  <div>
    <!-- Totalizer -->
    <BackofficeTotalizer
      :description="totalizerDescription"
      :totalizer="totalizer"
    />
    <!-- Filters -->
    <b-card no-body>
      <div class="m-md-2 mb-2 mx-2 mt-0">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        show-empty
        empty-text="Não foram encontradas NPS para esta busca"
        :busy="loading.table"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.customer_name }}</span>
          </div>
        </template>
        <!-- Column: NPS -->
        <template #cell(nps_average)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.nps_average | toPercentage}}</span>
          </div>
        </template>
        <!-- Column: justificativa -->
        <template #cell(justification_answer)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.justification_answer }}</span>
          </div>
        </template>
        <!-- Column: Duração de cliente -->
        <template #cell(customer_period)="data">
          <div class="text-wrap">
            <span class="align-text-top">
                {{ data.item.customer_period != null ? (data.item.customer_period == 1 ? data.item.customer_period + " mês" : data.item.customer_period + " meses") : "" }}
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} a {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import BackofficeTotalizer from "../components/BackofficeTotalizer";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    vSelect,
    BackofficeTotalizer
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: {
        table: false
      },
      tableColumns: [
        { key: "customer_name", label: "Cliente", sortable: true },
        { key: "nps_survey_status", label: "Status da Pesquisa", sortable: true },
        { key: "nps_average", label: "NPS", sortable: true },
        { key: "justification_answer", label: "Justificativa", sortable: true },
        { key: "customer_period", label: "Duração do cliente", sortable: true }
      ],
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null
    };
  },
  computed: {
    ...mapGetters({
      npsSearchResult: types.NPS_CUSTOMERS_LIST,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      totalizer: types.NPS_TOTALIZER
    }),
    consultant: function(){
      if(this.$route.params.consultant){
        return [this.$route.params.consultant]
      }
      return []
    },
    totalizerDescription(){
      if(this.consultant.length > 0 && this.items.length > 0){
        return this.items[0].consultant_name
      }
      return ''
    },
    items: function () {
      return this.npsSearchResult?.data || [];
    },
    totalItems: function () {
      return this.npsSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.npsSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.npsSearchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchNps: types.GET_NPS_CUSTOMERS_LIST,
      getTotalizer: types.GET_NPS_TOTALIZER
    }),
    search(currentPage = undefined) {
      this.loading.table = true;
      const {
        consultant,
        order,
        itemsPerPage
      } = this
      this.searchNps({
        consultant,
        order,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
        limit: itemsPerPage
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os NPS. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
        this.getTotalizer({
          consultant: this.consultant,
        }).catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao recuperar os dados do totalizador. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    sort(event) {
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? "desc":"asc";
        this.order = [event.sortBy , direction];
        this.sortField = event.sortBy;
      }
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
